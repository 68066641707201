// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-standard-page-js": () => import("./../src/templates/standard-page.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-book-us-page-js": () => import("./../src/templates/book-us-page.js" /* webpackChunkName: "component---src-templates-book-us-page-js" */),
  "component---src-templates-gigs-page-js": () => import("./../src/templates/gigs-page.js" /* webpackChunkName: "component---src-templates-gigs-page-js" */),
  "component---src-templates-discography-page-js": () => import("./../src/templates/discography-page.js" /* webpackChunkName: "component---src-templates-discography-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-guestbook-page-js": () => import("./../src/templates/guestbook-page.js" /* webpackChunkName: "component---src-templates-guestbook-page-js" */),
  "component---src-templates-thanks-page-js": () => import("./../src/templates/thanks-page.js" /* webpackChunkName: "component---src-templates-thanks-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

